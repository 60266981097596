<template>
  <div>
    <template>
      <b-badge class="d-none d-sm-block overflow-hidden" variant="info" show size="sm">{{ title }}</b-badge>
    </template>
    <b-navbar type="dark" variant="dark">
      <b-navbar-nav v-if="lastChecked!=''">
        <b-badge class="ml-2 d-none d-sm-block" variant="info" show :size="buttonSize">Last checked: {{ lastChecked }}</b-badge>
      </b-navbar-nav>
      <b-navbar-nav v-if="lastChecked==''">
        <b-spinner class="ml-2" label="Loading..." variant="info"></b-spinner>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-button-group :size="buttonSize">
          <b-button class="ml-2" @click="show" variant="secondary" title="Refresh User History">
            <b-icon icon="arrow-clockwise"></b-icon>
          </b-button>
          <b-button class="ml-2" @click="handleClose" variant="secondary" title="Close User History">
            <b-icon icon="file-x"></b-icon>
          </b-button>
        </b-button-group>
      </b-navbar-nav>
    </b-navbar>
    <b-list-group>
      <b-list-group-item class="bg-dark text-light">
        <b-button variant="secondary" size="sm" @click="showStudies=!showStudies" :showStudies="showStudies" v-b-toggle.collapse-studies>{{showStudies ? '−' : '+'}}</b-button>
        <span class="ml-2 font-weight-bold">Studies Viewed</span> <b-badge variant="info" pill>{{studyItems.length}}</b-badge>
        <b-collapse id="collapse-studies" visible>
          <div class="tableDiv">
          <b-table responsive="true" class="mt-2 small" striped head-variant="dark" table-variant="secondary" :items="studyItems" :fields="studyFields" :sort-by.sync="studySortBy" :sort-desc.sync="studySortDesc" :sort-compare="sortCompare" primary-key="timestamp">
            <template #cell(images_timestamp)="data">
              {{(data.item.images_timestamp==null) ? '---' : new Date(data.item.images_timestamp).toLocaleString(locale)}}
            </template>
            <template #cell(report_timestamp)="data">
              {{(data.item.report_timestamp==null) ? '---' : new Date(data.item.report_timestamp).toLocaleString(locale)}}
            </template>
            <template #cell(study_date_time)="data">
              {{(data.item.study_date_time==null) ? '---' : new Date(data.item.study_date_time).toLocaleString(locale)}}
            </template>
          </b-table>
          </div>
        </b-collapse>
      </b-list-group-item>
      <b-list-group-item class="bg-dark text-light">
        <b-button variant="secondary" size="sm" @click="showReports=!showReports" :showReports="showReports" v-b-toggle.collapse-reports>{{showReports ? '−' : '+'}}</b-button>
        <span class="ml-2 font-weight-bold">Reports Saved</span> <b-badge variant="info" pill>{{reportItems.length}}</b-badge>
        <b-collapse id="collapse-reports" visible>
          <div class="tableDiv">
          <b-table responsive="true" class="mt-2 small" striped head-variant="dark" table-variant="secondary" :items="reportItems" :fields="reportFields" :sort-by.sync="reportSortBy" :sort-desc.sync="reportSortDesc" :sort-compare="sortCompare" primary-key="timestamp">
            <template #cell(timestamp)="data">
              {{(data.item.timestamp==null) ? '---' : new Date(data.item.timestamp).toLocaleString(locale)}}
            </template>
            <template #cell(study_date_time)="data">
              {{(data.item.study_date_time==null) ? '---' : new Date(data.item.study_date_time).toLocaleString(locale)}}
            </template>
          </b-table>
          </div>
        </b-collapse>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
//import permissions from '../common/permissions'
import webServices from '../common/webServices'

export default {
  name: 'userHistory',
  components: {
  },
  data() {
    return {
      buttonSize: "sm",
      lastChecked: '',
      reportItems: [],
      reportFields: [ 
        { key: 'group', label: 'Group/Tenant', sortable: true },
        { key: 'patient_name', label: 'Patient Name', sortable: false },
        { key: 'patient_id', label: 'MRN', sortable: true },
        { key: 'study_date_time', label: 'Study Date/Time', sortable: true },
        { key: 'status', label: 'Report Status', sortable: true },
        { key: 'timestamp', label: 'Report Saved', sortable: true },
      ],
      reportSortBy: 'save_date_time',
      reportSortDesc: true,
      showReports: true,
      showStudies: true,
      studyItems: [],
      studyFields: [ 
        { key: 'group', label: 'Group/Tenant', sortable: true },
        { key: 'patient_name', label: 'Patient Name', sortable: false },
        { key: 'patient_id', label: 'MRN', sortable: true },
        { key: 'study_date_time', label: 'Study Date/Time', sortable: true },
        { key: 'images_timestamp', label: 'Images Last Viewed', sortable: true },
        { key: 'report_timestamp', label: 'Report Last Viewed', sortable: true }
      ],
      studySortBy: 'images_date_time',
      studySortDesc: true
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale
    },
    title() {
      var user = "User"
      let userProfile = this.$store.state.userProfile
      if (userProfile != null) {
        if (("firstName" in userProfile) && ("lastName" in userProfile)) {
          user = userProfile["firstName"]+" "+userProfile["lastName"]
        }
        else {
          user = "History for User: " + this.$store.state.userProfile.username
        }
      }
      return "Recent History for "+user+" (30 days)"
    }
  },
  watch: {
  },
  methods: {
    show() {
      this.lastChecked = ''
      this.reportItems = []
      this.studyItems = []
      this.$log.debug("Read user history")
      webServices.readUserHistory()
      .then(response => {
        this.reportItems.push(...response.reportItems)
        this.studyItems.push(...response.studyItems)
        this.lastChecked = new Date().toLocaleString(this.$store.state.locale)
      })
      .catch(err => {
        this.$log.error("Error reading user history: "+err.message)
      })
    },
    handleClose() {
      this.$store.commit('changeActiveComponent', '')
    },
    sortCompare(aRow, bRow, key /*, sortDesc, formatter, compareOptions, compareLocale*/) {
      if (key.endsWith('timestamp')) {
        const aD = new Date(aRow[key])
        const bD = new Date(bRow[key])
        // +TODO+ Handle ISO date format
        return bD - aD
      }
      else {
        // Fallback to default sort routine.
        //
        return false;
      }
    }
  }
};
</script>
<style scoped>
.tableDiv {
  overflow: auto;
  max-height: 500px;
}
</style>